import { navigate } from 'gatsby'
import React, { useContext, useEffect } from 'react'
import { connect } from 'react-redux'
import SelectorContainer from '../../../src/common/components/FunnelSelector/Entrada/SelectorContainer'
import SelectorForm from '../../../src/common/components/FunnelSelector/Entrada/SelectorForm'
import Step, {
  findNext,
  InSkipInfo,
} from '../../../src/common/components/FunnelSelector/SelectorSteps'
import ResponsiveImages from '../../../src/common/components/ResponsiveImages/ResponsiveImages'
import SearchNeumaticos from '../../../src/common/components/searchNeumaticosCardHome/index'
import ResponsiveSliderBanner from '../../../src/common/components/ResponsiveSliderBanner/ResponsiveSliderBanner'
import Title from '../../../src/common/components/Title'
import { reset } from '../../../src/common/context/actions/selectorActions'
import AppContext, { IAppState } from '../../../src/common/context/context'
import { selectSkipInfo } from '../../../src/common/context/selectors/selectorSelectors'
import { locale } from '../../../src/common/i18n'
import { temporadaMapping } from '../../../src/specific/helpers/gtmReporter'
import { getImages, ImageInputData } from '../../../src/common/utils/imagesS3'
import { SeoData } from '../../../src/common/types/Seo'
import route from '../../../src/common/utils/route'

const placeholderImage = {
  Key: '',
  url: 'https://via.placeholder.com/800x200?text=Placeholder+Image',
  link: '',
  alt: '',
}
interface InSeoData {
  seoData: SeoData
}
interface Props {
  styles: { [k: string]: string }
  data?: InSeoData | ImageInputData
  tablet?: boolean
  desktop?: boolean
}

const LandingFurgonetaEs = ({ styles, data }: Props) => {
  const banners_mobile = getImages(
    data[`banners_${locale}_mobile`].nodes,
    locale,
    placeholderImage
  )
  const banners_desktop = getImages(
    data[`banners_${locale}_desktop`].nodes,
    locale,
    placeholderImage
  )
  const [image_mobile] = getImages(
    data[`imagenes_${locale}_mobile`].nodes,
    locale,
    placeholderImage
  )
  const [image_desktop] = getImages(
    data[`imagenes_${locale}_desktop`].nodes,
    locale,
    placeholderImage
  )
  return (
    <div className={styles.container}>
      <Title text="Neumáticos para Furgoneta" />
      <Selector styles={styles} />
      <article className={styles.description}>
        <p className={styles.main_description}>
          La elección de unos buenos neumáticos resulta fundamental para los
          profesionales del transporte por carretera. Unos buenos neumáticos de
          furgoneta pueden suponer un ahorro importante en la economía de una
          empresa al final del año e inciden directamente en la calidad de
          conducción y en la seguridad del transportista al volante.
        </p>
        <ResponsiveSliderBanner
          desktopImages={banners_desktop}
          mobileImages={banners_mobile}
          sizesVariant={'banner_landings'}
        />
        <section className={styles.section}>
          <h2>
            ¿Cuál es el diferencial que ofrecen los neumáticos de furgoneta?
          </h2>
          <p>
            Los neumáticos de furgoneta se diferencian de los de coche tipo
            turismo en dos aspectos: están reforzados para ser más resistentes y
            su índice de carga es superior.
            <br />
            El índice de carga en neumático de furgoneta se encuentra entre el
            indicador 100 y el 115, mientras que en un coche este indicador se
            sitúa entre el 80 y el 100. El código de velocidad, por contrario,
            en furgonetas es inferior estando prepeados sus neumáticos para
            alcanzar velocidades máximas de entre 175Km/h y 185 Km/h.
          </p>
        </section>
        <ResponsiveImages
          imageDesktop={image_desktop}
          imageMobile={image_mobile}
        />
        <section className={styles.section}>
          <h2>
            ¿Qué significa que los neumáticos de furgoneta están reforzados?
          </h2>
          <p>
            A diferencia de los neumáticos de coche, los neumáticos de furgoneta
            tienden a ser usados de una forma más intensa dentro del tráfico
            urbano. Al tener que soportar mayores cargas de peso, si mantuvieran
            la estructura de un neumático de coche al uso, estos neumáticos
            sufrirían mayor cantidad de pinchazos y reventones. Para evitarlo,
            los neumáticos de furgoneta están dotados con escudos protectores en
            sus laterales y una estructura de sus lonas reforzada.
            <br />
            Por este motivo, las marcas de neumáticos fabrican modelos
            exclusivos para ser usados en furgonetas. Más pesados, debido a que
            están reforzados en estructura, pero más resistentes a la rodadura,
            a arañazos y altas presiones. Algunos ejemplos de modelos
            reconocidos para furgonetas son Michelin Agilis CrossClimate,
            Pirelli Carrier o los Goodyear EfficientGrip.
          </p>
        </section>
      </article>
    </div>
  )
}

interface SelectorProps {
  styles: { [k: string]: string }
  dispatch: (action: any) => void
  skipInfo: InSkipInfo
}
const ConnectedSelector = ({ dispatch, styles, skipInfo }: SelectorProps) => {
  const { selectorState } = useContext(AppContext)
  // Initialize selector values
  useEffect(() => {
    dispatch(
      reset({
        keepValues: false,
        marca: null,
        origen: route('landing-furgoneta'),
        temporada: {
          selectable: process.env.GATSBY_WEB === 'rodi',
          value: 'verano',
        },
        tipoVehiculo: 1,
      })
    )
  }, [])

  return (
    <React.Fragment>
      <SearchNeumaticos
        gtmData={{
          action: 'furgoneta',
          runflat: selectorState.runflat.value,
          tipo_neumaticos: temporadaMapping[selectorState.temporada.value],
        }}
      />

      <div className={styles.selector_container}>
        <SelectorContainer
          showMarcas={false}
          origin={route('landing-furgoneta')}>
          {({ ...props }) => (
            <SelectorForm
              showTemporada={false}
              fromModal={false}
              handleSubmit={() => {
                const next = findNext(Step.SELECT_MARCAS, 'desktop', skipInfo)
                navigate(next.routes.desktop)
              }}
              {...props}
            />
          )}
        </SelectorContainer>
      </div>
    </React.Fragment>
  )
}
const Selector = connect((state: IAppState) => ({
  skipInfo: selectSkipInfo(state),
}))(ConnectedSelector)

const LandingFurgoneta = ({ ...props }: Props) => (
  <LandingFurgonetaEs {...props} />
)

export default LandingFurgoneta
